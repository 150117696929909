import { useRouter } from 'next/router'

import Preloader from '@/components/base/Preloader'

import { useSession } from '@/contexts/Session'

const Guest = ({ children }) => {
    const { push, asPath } = useRouter()
    const { isAuthenticated, nextURL, user } = useSession({
        required: false,
        onAuthenticated() {
            if (
                (nextURL
                    ? nextURL
                    : `/projects?type=${
                          user?.projectTypeAccess === 'all'
                              ? 'housetypes'
                              : user?.projectTypeAccess
                      }&standard=${
                          user?.standardAccess === 'both'
                              ? ''
                              : user?.standardAccess
                      }`) !== asPath
            ) {
                push(
                    nextURL
                        ? nextURL
                        : `/projects?type=${
                              user?.projectTypeAccess === 'all'
                                  ? 'housetypes'
                                  : user?.projectTypeAccess
                          }&standard=${
                              user?.standardAccess === 'both'
                                  ? ''
                                  : user?.standardAccess
                          }`
                )
            }
        },
    })

    if (!isAuthenticated) {
        return children
    }

    return <Preloader></Preloader>
}

export default Guest
